import "./App.css";
import "./components/css/Header.css"; // Import the CSS file
import React from "react";
import CompoundCard from "./components/Compundcard";

function App() {
  return (
    <div>
      {/* <Header /> */}
      <CompoundCard />
      {/* <Testss /> */}
    </div>
  );
}

export default App;
