// src/components/Header.js
import React from 'react';
import './css/Header.css'; // Create this file for styling

const Header = () => {
  return (
    <header className="app-header">
      <div className="header-left">
        <img src="/logo512.png" alt="Icon_Flipto" className="header-icon" />
        <span className="header-text">flipto</span>
      </div>
    </header>
  );
};

export default Header;
