import React, { useState, useEffect } from "react";
import Card from "./Card";
import Header from "./Header";
import "./css/Tallcard.css"; // Import your CSS file
import "./css/Header.css"; // Import your CSS file
import "./css/Styles.css";
import busy from "../assets/busy.svg";
import online from "../assets/online.svg";
import offline from "../assets/offline.svg";

import axios from "axios";

const containerStyle = {
  maxWidth: "90%", // Set your preferred maximum width percentage
  width: "520px",
  height: "100%",
  backgroundColor: "#363636",
  borderTopLeftRadius: "0px",
  borderTopRightRadius: "0px",
  borderBottomLeftRadius: "10px",
  borderBottomRightRadius: "10px",
  display: "flex",
  flexDirection: "column", // Adjusted for a tall container
  // justifyContent: 'center',
  // alignItems: 'center',
  maxHeight: "100vh",
  margin: "auto", // Center horizontally
};

const PaymentcontainerStyle = {
  maxWidth: "90%", // Set your preferred maximum width percentage
  width: "100%",
  height: "100%",

  // backgroundColor: "#363636",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column", // Adjusted for a tall container
  // justifyContent: 'center',
  alignItems: "center",
  maxHeight: "100vh",
  margin: "auto", // Center horizontally
};

const formStyle = {
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  justifyContent: "space-between",
  // alignItems: 'center',
};
const rowContainerStyle = {
  width: "100%",
  display: "flex",
  height: "100%",
  // background: "#242424",
  borderRadius: 10,
  // justifyContent: 'space-between', // Space evenly between the two fields
};
const FinalrowContainerStyle = {
  width: "100%",
  display: "flex",
  height: "23%",
  flexDirection: "row",
  alignItems: "center",
  // background: "#242424",
  // borderRadius: 10,
  justifyContent: "space-between", // Space evenly between the two fields
};

const inputStyle = {
  width: "70%", // Adjust the width as needed
  height: "40px",
  borderRadius: "10px",
  padding: "8px",
  border: "none",
  backgroundColor: "#242424",
  color: "#fff",
};

const selectFiatStyle = {
  width: "50%", // Adjust the width as needed
  height: "56px",
  padding: "8px",
  borderRadius: "10px",
  background: "#242424",
  color: "#fff",
  border: "none",
  marginLeft: "8px",
};
const selectPlatformStyle = {
  width: "100%", // Adjust the width as needed
  height: "40px",
  padding: "12px",
  borderRadius: "10px",
  background: "#242424",
  color: "#fff",
  border: "none",
  boxSizing: "border-box", // Include padding and border in the width
};

const inputUidStyle = {
  width: "100%",
  height: "40px",
  padding: "16px",
  borderRadius: "10px",
  background: "#242424",
  color: "#fff",
  border: "none",
  boxSizing: "border-box", // Include padding and border in the width
};

const labelStyle = {
  color: "white",
  fontWeight: "400",
  FontFace: "Poppins",
  fontSize: "14px",
  wordWrap: "break-word",
  paddingBottom: "5px",
};
const limitStyle = {
  // Limit Rp10.000 - Rp1.000.000
  color: "#25E9E2",
  fontSize: "14px",
  fontFace: "Poppins",
  fontWeight: "400",
  wordWrap: "break-word",
};
const newDivStyle = {
  borderRadius: 10,
  padding: "16px",
  height: "140px",
  border: "2px #25E9E2 dashed",
  display: "inline-block",
  marginTop: "20px", // Add margin-top to create space below the UID form
  // Additional styles for the new div
};
const labelStyle2 = {
  // color: "#25E9E2",
  color: "white",
  fontWeight: "400",
  FontFace: "Poppins",
  fontSize: "14px",
  wordWrap: "break-word",
  paddingBottom: "5px",
  paddingTop: "12px",
};
const labelStyle3 = {
  // color: "#25E9E2",
  color: "white",
  fontWeight: "400",
  FontFace: "Poppins",
  fontSize: "14px",
  wordWrap: "break-word",
  paddingBottom: "5px",
  paddingTop: "12px",
  marginTop: "15px",
};

const FinalResultStyle = {
  // Limit Rp10.000 - Rp1.000.000
  color: "#25E9E2",
  fontSize: "14px",
  fontFace: "Poppins",
  fontWeight: "400",
  wordWrap: "break-word",
};
const FinalFeeResultStyle = {
  // Limit Rp10.000 - Rp1.000.000
  color: "#25E9E2",
  fontSize: "14px",
  fontFace: "Poppins",
  fontWeight: "bold",
  wordWrap: "break-word",
};
const FinalLabelStyle = {
  // color: "#25E9E2",
  color: "white",
  fontWeight: "400",
  FontFace: "Poppins",
  fontSize: "14px",
  wordWrap: "break-word",
};

const backbuttonStyle = {
  width: "30px",
  height: "30px",

  cursor: "pointer",
};

const formatNumber = (number) => {
  // Use Intl.NumberFormat for formatting
  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  // Use the formatter to format the number
  return formatter.format(number);
};

// BEGIN OF TALLCARD
const Tallcard = () => {
  const client = axios.create({
    baseURL: "https://app.mykarisma.org/api/v1/flipto/get-config",
  });
  const fetchPosts = async () => {
    try {
      let response = await client.get(client.defaults.baseURL);
      // setDatas(response.data);
      const responseData = response.data;
      // Update service status
      const serviceData = responseData.data.find(
        (item) => item.key === "service_status"
      );
      const KursData = responseData.data.find(
        (item) => item.key === "usd_rate"
      );
      const LimitUSD = responseData.data.find(
        (item) => item.key === "limit_usdt"
      );
      if (serviceData) {
        setServiceStatus(serviceData.value);
      }
      if (KursData) {
        setKursData(KursData.value);
      }
      if (LimitUSD) {
        setLimitUSD(LimitUSD.value);
      }
      // Check if it's operating hours based on current time
      const today = new Date();
      const hour = today.getHours();
      setIsOperatingHours(hour >= 1 && hour < 23); // 8:00 AM - 5:00 PM
    } catch (error) {
      console.log(error);
    }
  };
  const [KursData, setKursData] = useState(15000); // Default to offline
  const [LimitUSD, setLimitUSD] = useState(1); // Default to offline
  const [isOperatingHours, setIsOperatingHours] = useState(false);
  const [amount, setAmount] = useState("");
  const [selectedOption, setSelectedOption] = useState("fiat");
  const [destinationPlatform, setDestinationPlatform] = useState("");
  const [destinationBank, setDestinationbank] = useState("");
  const [PaymentdestinationBank, setPaymentdestinationBank] = useState("");
  const [PaymentdestinationCrypto, setPaymentdestinationCrypto] = useState("");
  const [PaymentuserID, setPaymentuserID] = useState("");
  const [userID, setUserID] = useState("");
  const [accountNumber, setaccountNumber] = useState("");
  const [PaymentaccountNumber, setPaymentaccountNumber] = useState("");
  const [accountOwnerName, setaccountOwnerName] = useState("");
  const [PaymentaccountOwnerName, setPaymentaccountOwnerName] = useState("");
  const kurs = KursData;
  const limitUSDT = LimitUSD;
  const limitIDR = kurs * limitUSDT;
  const finalFiat = amount / kurs;
  const finalCrypto = amount * kurs;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formFiatSubmitted, setFormFiatSubmitted] = useState(false);

  const [serviceStatus, setServiceStatus] = useState("offline"); // Default to offline

  // FORM SUBMISSION LOGIC {***************************************************************************}
  const handleSubmitFiat = (event) => {
    event.preventDefault();

    const amountFiat = event.target.amount.value;
    const optionFiat = event.target.paymentType.value;
    const destinationPlatformFiat = event.target.destinationPlatform.value;
    const userIDFiat = event.target.userID.value;

    console.log("Submitted:", {
      amountFiat,
      optionFiat,
      destinationPlatformFiat,
      userIDFiat,
    });
    setFormFiatSubmitted(true);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const amountCrypto = event.target.amount.value;
    const selectedOptionCrypto = event.target.paymentType.value;
    const destinationBankCrypto = event.target.destinationBank.value;
    const accountNumberCrypto = event.target.accountNumber.value;
    const accountOwnerNameCrypto = event.target.accountOwnerName.value;

    console.log("Submitted:", {
      amountCrypto,
      selectedOptionCrypto,
      destinationBankCrypto,
      accountNumberCrypto,
      accountOwnerNameCrypto,
    });
    setFormSubmitted(true);
  };
  // END OF FORM SUBMISSION LOGIC {***************************************************************************}
  const handleBack = () => {
    setFormSubmitted(false);
  };
  const FiathandleBack = () => {
    setFormFiatSubmitted(false);
  };

  useEffect(() => {
    // Reset the amount when the selectedOption changes
    setAmount("");
    fetchPosts();
  }, [selectedOption]);

  let statusImage;
  let statusInfo;
  let statusText;
  if (serviceStatus === "online" && isOperatingHours) {
    statusImage = <img src={online} alt="Online" />;
    statusText = "Operating hours: 08:00 AM - 05:00 PM (WIB)";
    statusInfo = "Online";
  } else if (serviceStatus === "online" && !isOperatingHours) {
    statusImage = <img src={offline} alt="Offline" />;
    statusText = "Currently outside operating hours";
    statusInfo = "Offline";
  } else if (serviceStatus === "busy" && isOperatingHours) {
    statusImage = <img src={busy} alt="Busy" />;
    statusText = "Operating hours: 08:00 AM - 05:00 PM (WIB)";
    statusInfo = "Busy";
  } else if (serviceStatus === "busy" && !isOperatingHours) {
    statusImage = <img src={offline} alt="Offline" />;
    statusText = "Currently outside operating hours";
    statusInfo = "Offline";
  } else if (serviceStatus === "offline" && isOperatingHours) {
    statusImage = <img src={offline} alt="Offline" />;
    statusText = "Currently offline";
    statusInfo = "Offline";
  } else {
    statusImage = <img src={offline} alt="Offline" />;
    statusInfo = "Offline";
    statusText = "Currently outside operating hours";
  }

  const isCryptoFormFilled = () => {
    // Check if all required fields are filled
    return (
      amount !== "" &&
      destinationBank !== "" &&
      accountNumber !== "" &&
      accountOwnerName !== "" &&
      serviceStatus === "online"
    );
  };
  const isFiatPaymentMethodFormFilled = () => {
    // Check if all required fields are filled
    return (
      PaymentdestinationBank !== "" &&
      PaymentaccountNumber !== "" &&
      PaymentaccountOwnerName !== "" &&
      serviceStatus === "online"
    );
  };
  const isCryptoPaymentMethodFormFilled = () => {
    // Check if all required fields are filled
    return (
      PaymentdestinationCrypto !== "" &&
      PaymentuserID !== "" &&
      serviceStatus === "online"
    );
  };
  const isFiatFormFilled = () => {
    // Check if all required fields are filled
    return (
      amount !== "" &&
      destinationPlatform !== "" &&
      userID !== "" &&
      serviceStatus === "online"
    );
  };
  const submitButtonStyle = {
    width: "100%",
    height: "40px",
    marginTop: "35px",
    padding: "8px",
    fontSize: 15,
    fontFace: "Poppins",
    fontWeight: "400",
    borderRadius: "10px",
    border: "none",
    cursor: isCryptoFormFilled() ? "pointer" : "not-allowed", // Change cursor based on form fill status
    color: isCryptoFormFilled() ? "#000" : "#888", // Change text color based on form fill status
    backgroundColor: isCryptoFormFilled() ? "#25E9E2" : "#ccc", // Change background color based on form fill status
  };
  const submitPaymentFiatButtonStyle = {
    width: "100%",
    height: "40px",
    marginTop: "35px",
    padding: "8px",
    fontSize: 15,
    fontFace: "Poppins",
    fontWeight: "400",
    borderRadius: "10px",
    border: "none",
    cursor: isFiatPaymentMethodFormFilled() ? "pointer" : "not-allowed", // Change cursor based on form fill status
    color: isFiatPaymentMethodFormFilled() ? "#000" : "#888", // Change text color based on form fill status
    backgroundColor: isFiatPaymentMethodFormFilled() ? "#25E9E2" : "#ccc", // Change background color based on form fill status
  };
  const submitPaymentCryptoButtonStyle = {
    width: "100%",
    height: "40px",
    marginTop: "35px",
    padding: "8px",
    fontSize: 15,
    fontFace: "Poppins",
    fontWeight: "400",
    borderRadius: "10px",
    border: "none",
    cursor: isCryptoPaymentMethodFormFilled() ? "pointer" : "not-allowed", // Change cursor based on form fill status
    color: isCryptoPaymentMethodFormFilled() ? "#000" : "#888", // Change text color based on form fill status
    backgroundColor: isCryptoPaymentMethodFormFilled() ? "#25E9E2" : "#ccc", // Change background color based on form fill status
  };

  const submitFiatButtonStyle = {
    width: "100%",
    height: "40px",
    marginTop: "35px",
    padding: "8px",
    fontSize: 15,
    fontFace: "Poppins",
    fontWeight: "400",
    borderRadius: "10px",
    border: "none",
    cursor: isFiatFormFilled() ? "pointer" : "not-allowed", // Change cursor based on form fill status
    color: isFiatFormFilled() ? "#000" : "#888", // Change text color based on form fill status
    backgroundColor: isFiatFormFilled() ? "#25E9E2" : "#ccc", // Change background color based on form fill status
  };
  const HeaderPaymentMethodFiat = () => {
    //
    return (
      <div>
        <div
          style={{ direction: "row", display: "flex", marginBottom: "20px" }}
        >
          <img
            src="./ep_back.svg"
            onClick={FiathandleBack}
            alt="back-button"
            style={backbuttonStyle}
          ></img>
          <div
            style={{
              color: "white",
              width: "92%",
              alignSelf: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            Pilih Metode Pembayaran
          </div>
        </div>

        <div
          style={{
            display: "flex",
            direction: "row",
            alignSelf: "center",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <svg
            height="30"
            width="20%"
            style={{ verticalAlign: "middle", padding: "10px" }}
          >
            <line
              x1="0"
              y1="15"
              x2="100%"
              y2="15"
              style={{
                stroke: "#525252",
                strokeWidth: "2",
                strokeDasharray: "5, 5",
              }}
            />
          </svg>
          <div className="circle">1</div>
          <svg
            height="30"
            width="20%"
            style={{ verticalAlign: "middle", padding: "10px" }}
          >
            <line
              x1="0"
              y1="15"
              x2="100%"
              y2="15"
              style={{
                stroke: "#525252",
                strokeWidth: "2",
                strokeDasharray: "5, 5",
              }}
            />
          </svg>
          <div className="circle">2</div>
          <svg
            height="30"
            width="20%"
            style={{ verticalAlign: "middle", padding: "10px" }}
          >
            <line
              x1="0"
              y1="15"
              x2="100%"
              y2="15"
              style={{
                stroke: "#525252",
                strokeWidth: "2",
                strokeDasharray: "5, 5",
              }}
            />
          </svg>
          <div className="circle-inactive">3</div>
          <svg
            height="30"
            width="20%"
            style={{ verticalAlign: "middle", padding: "10px" }}
          >
            <line
              x1="0"
              y1="15"
              x2="100%"
              y2="15"
              style={{
                stroke: "#525252",
                strokeWidth: "2",
                strokeDasharray: "5, 5",
              }}
            />
          </svg>
        </div>
      </div>
    );
  };
  const HeaderPaymentMethodCrypto = () => {
    //
    return (
      <div>
        <div
          style={{ direction: "row", display: "flex", marginBottom: "20px" }}
        >
          <img
            src="./ep_back.svg"
            onClick={handleBack}
            alt="back-button"
            style={backbuttonStyle}
          ></img>
          <div
            style={{
              color: "white",
              width: "100%",
              alignSelf: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            Pilih Metode Pembayaran
          </div>
        </div>

        <div
          style={{
            display: "flex",
            direction: "row",
            alignSelf: "center",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <svg
            height="30"
            width="20%"
            style={{ verticalAlign: "middle", padding: "10px" }}
          >
            <line
              x1="0"
              y1="15"
              x2="100%"
              y2="15"
              style={{
                stroke: "#525252",
                strokeWidth: "2",
                strokeDasharray: "5, 5",
              }}
            />
          </svg>
          <div className="circle">1</div>
          <svg
            height="30"
            width="20%"
            style={{ verticalAlign: "middle", padding: "10px" }}
          >
            <line
              x1="0"
              y1="15"
              x2="100%"
              y2="15"
              style={{
                stroke: "#525252",
                strokeWidth: "2",
                strokeDasharray: "5, 5",
              }}
            />
          </svg>
          <div className="circle">2</div>
          <svg
            height="30"
            width="20%"
            style={{ verticalAlign: "middle", padding: "10px" }}
          >
            <line
              x1="0"
              y1="15"
              x2="100%"
              y2="15"
              style={{
                stroke: "#525252",
                strokeWidth: "2",
                strokeDasharray: "5, 5",
              }}
            />
          </svg>
          <div className="circle-inactive">3</div>
          <svg
            height="30"
            width="20%"
            style={{ verticalAlign: "middle", padding: "10px" }}
          >
            <line
              x1="0"
              y1="15"
              x2="100%"
              y2="15"
              style={{
                stroke: "#525252",
                strokeWidth: "2",
                strokeDasharray: "5, 5",
              }}
            />
          </svg>
        </div>
      </div>
    );
  };
  const phone_number = +6285161606406;
  const handleSubmitPaymentFiat = (event) => {
    event.preventDefault();

    // Construct the WhatsApp message content
    const messageContent = `
    Halo admin Flipto, saya ingin melakukan order IDR to USDT dengan data berikut:
    Jumlah : Rp ${amount}
    Jumlah USDT : ${finalFiat.toFixed(2)}
    Bank Pembayaran : ${PaymentdestinationBank}
    Nomor Rekening Pembayar : ${PaymentaccountNumber}
    Nama Pembayar : ${PaymentaccountOwnerName}
    `;

    // Construct the WhatsApp URL
    const whatsappURL = `https://wa.me/${phone_number}?text=${encodeURIComponent(
      messageContent
    )}`;

    // Open the WhatsApp URL in a new tab or window
    window.open(whatsappURL, "_blank");
  };
  const handleSubmitPaymentCrypto = (event) => {
    event.preventDefault();

    // Construct the WhatsApp message content
    const messageContent = `
    Halo admin Flipto, saya ingin melakukan order USDT to IDR dengan data berikut:
    Platform : ${PaymentdestinationCrypto}
    UID : ${PaymentuserID}
    Jumlah USDT : ${amount.toFixed(2)}
    Bank Penerima : ${destinationBank}
    Nomor Rekening : ${accountNumber}
    Nama Rekening Penerima : ${accountOwnerName}
    Jumlah : Rp ${finalCrypto}
    `;

    // Construct the WhatsApp URL
    const whatsappURL = `https://wa.me/${phone_number}?text=${encodeURIComponent(
      messageContent
    )}`;

    // Open the WhatsApp URL in a new tab or window
    window.open(whatsappURL, "_blank");
  };

  const renderForm = () => {
    if (selectedOption === "fiat") {
      return (
        <div>
          <div style={containerStyle}>
            <form style={formStyle} onSubmit={handleSubmitFiat}>
              <div style={labelStyle}>Jumlah IDR</div>
              <div style={rowContainerStyle}>
                <input
                  type="number"
                  id="amount"
                  name="amount"
                  value={amount}
                  onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                  onChange={(e) => {
                    const enteredValue = e.target.value;
                    const parsedValue = parseInt(enteredValue, 10);

                    // Check if the parsed value is within the desired range
                    if (
                      !isNaN(parsedValue) &&
                      parsedValue >= 1 &&
                      parsedValue <= limitIDR
                    ) {
                      setAmount(parsedValue);
                    } else {
                      // If the entered value is not within the range, set it to 1 or the limit
                      setAmount(enteredValue < 1 ? 1 : limitIDR);
                    }
                  }}
                  placeholder="Masukkan Jumlah"
                  style={inputStyle}
                />
                <select
                  id="paymentType"
                  name="paymentType"
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                  style={selectFiatStyle}
                >
                  <option value="fiat">By Fiat</option>
                  <option value="crypto">By Crypto</option>
                </select>
              </div>
              <p style={limitStyle}>
                Limit Rp10.000 - {formatNumber(limitIDR)}
              </p>
              <label htmlFor="destinationPlatform" style={labelStyle2}>
                Platform Tujuan
              </label>
              <select
                id="destinationPlatform"
                name="destinationPlatform"
                value={destinationPlatform}
                onChange={(e) => setDestinationPlatform(e.target.value)}
                style={selectPlatformStyle}
              >
                <option disabled={true} value="">
                  Pilih Satu
                </option>
                <option value="Mexc">Mexc</option>
                <option value="pintu">Pintu</option>
              </select>

              <label htmlFor="userID" style={labelStyle2}>
                {destinationPlatform === "Mexc"
                  ? "UID Mexc Kamu"
                  : destinationPlatform === "pintu"
                  ? "@username Pintu Kamu"
                  : "UID / @username"}
              </label>
              <input
                type="text"
                id="userID"
                name="userID"
                value={userID}
                onChange={(e) => setUserID(e.target.value)}
                placeholder="123XXX"
                style={inputUidStyle}
              />
              <div style={newDivStyle}>
                <div style={FinalrowContainerStyle}>
                  <p style={FinalLabelStyle}>Kurs</p>
                  <div style={FinalResultStyle}>{formatNumber(kurs || 0)}</div>
                </div>
                <div style={FinalrowContainerStyle}>
                  <p style={FinalLabelStyle}>Jumlah IDR</p>
                  <div style={FinalResultStyle}>
                    {formatNumber(amount || 0)}
                  </div>
                </div>
                <div style={FinalrowContainerStyle}>
                  <p style={FinalLabelStyle}>Biaya</p>
                  <div style={FinalFeeResultStyle}>Gratis</div>
                </div>
                <svg height="5" width="100%">
                  <line
                    x1="0"
                    y1="0"
                    x2="100%"
                    y2="0"
                    style={{ stroke: "#25E9E2", strokeWidth: "4" }}
                  />
                </svg>
                <div style={FinalrowContainerStyle}>
                  <p style={FinalLabelStyle}>Kamu akan dapat</p>
                  {/* <div style={FinalResultStyle}>{amount / limitIDR} USDT</div> */}
                  <div style={FinalResultStyle}>
                    {(finalFiat || 0).toFixed(2)} USDT
                  </div>
                </div>
              </div>
              <div style={rowContainerStyle}>
                <button
                  type="submit"
                  style={submitFiatButtonStyle}
                  disabled={!isFiatFormFilled()} // Disable button if form is not filled
                >
                  Lanjutkan
                </button>
              </div>
            </form>
          </div>
        </div>
      );
    } else if (selectedOption === "crypto") {
      return (
        <div style={containerStyle}>
          <form style={formStyle} onSubmit={handleSubmit}>
            <div style={labelStyle}>Jumlah USDT</div>
            <div style={rowContainerStyle}>
              <input
                type="number"
                id="amount"
                name="amount"
                value={amount}
                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                onChange={(e) => {
                  const enteredValue = e.target.value;

                  // Remove any existing commas from the entered value
                  const sanitizedValue = enteredValue.replace(/,/g, "");

                  // Check for the presence of the letter 'e' and prevent it
                  if (sanitizedValue.includes("e")) {
                    return;
                  }

                  // Parse the sanitized value as a float with up to 2 decimal places
                  const parsedValue = parseFloat(
                    sanitizedValue.replace(/[^\d.]/g, ""),
                    10
                  );

                  // Check if the parsed value is a valid number
                  if (
                    !isNaN(parsedValue) &&
                    parsedValue >= 1 &&
                    parsedValue <= limitUSDT
                  ) {
                    // Update the state with the parsed value rounded to 2 decimal places
                    setAmount(parseFloat(parsedValue.toFixed(2)));
                  } else {
                    // If the entered value is not within the range, set it to 1 or the limit
                    setAmount(sanitizedValue < 1 ? 1 : limitUSDT);
                  }
                }}
                placeholder="Masukkan Jumlah"
                style={inputStyle}
              />
              <select
                id="paymentType"
                name="paymentType"
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
                style={selectFiatStyle}
              >
                <option value="fiat">By Fiat</option>
                <option value="crypto">By Crypto</option>
              </select>
            </div>
            <p style={limitStyle}>Limit 1 USDT - {limitUSDT} USDT</p>
            <label htmlFor="destinationBank" style={labelStyle2}>
              Bank Tujuan
            </label>
            <select
              id="destinationBank"
              name="destinationBank"
              value={destinationBank}
              onChange={(e) => setDestinationbank(e.target.value)}
              style={selectPlatformStyle}
            >
              <option disabled={true} value="">
                Pilih Satu
              </option>
              <option value="BCA">BCA</option>
              <option value="SEAbank">SEA Bank</option>
            </select>

            <label htmlFor="accountNumber" style={labelStyle2}>
              Nomor Rekening
            </label>
            <input
              type="text"
              id="accountNumber"
              name="accountNumber"
              value={accountNumber}
              onChange={(e) => setaccountNumber(e.target.value)}
              placeholder="123XXX"
              style={inputUidStyle}
            />
            <label htmlFor="accountOwnerName" style={labelStyle2}>
              Nama Penerima Rekening
            </label>
            <input
              type="text"
              id="accountOwnerName"
              name="accountOwnerName"
              value={accountOwnerName}
              onChange={(e) => setaccountOwnerName(e.target.value)}
              placeholder="Budi"
              style={inputUidStyle}
            />
            <div style={newDivStyle}>
              <div style={FinalrowContainerStyle}>
                <p style={FinalLabelStyle}>Kurs</p>
                <div style={FinalResultStyle}>{formatNumber(kurs || 0)}</div>
              </div>
              <div style={FinalrowContainerStyle}>
                <p style={FinalLabelStyle}>Jumlah USDT</p>
                <div style={FinalResultStyle}>{amount || 0}</div>
              </div>
              <div style={FinalrowContainerStyle}>
                <p style={FinalLabelStyle}>Biaya</p>
                <div style={FinalFeeResultStyle}>Gratis</div>
              </div>
              <svg height="5" width="100%">
                <line
                  x1="0"
                  y1="0"
                  x2="100%"
                  y2="0"
                  style={{ stroke: "#25E9E2", strokeWidth: "4" }}
                />
              </svg>
              <div style={FinalrowContainerStyle}>
                <p style={FinalLabelStyle}>Kamu akan dapat</p>
                <div style={FinalResultStyle}>
                  {formatNumber(finalCrypto || 0)}
                </div>
              </div>
            </div>
            <div style={rowContainerStyle}>
              <button
                type="submit"
                style={submitButtonStyle}
                disabled={!isCryptoFormFilled()} // Disable button if form is not filled
              >
                Lanjutkan
              </button>
            </div>
          </form>
        </div>
      );
    }

    return null; // Return null for unknown option (optional)
  };
  return (
    <div>
      {/* Render the Card component if form is not submitted */}
      {!formSubmitted && !formFiatSubmitted && (
        <div>
          <Header />
          <div style={{ textAlign: "center" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                direction: "row",
                textAlign: "center",
              }}
            >
              {statusImage}

              <p
                style={{
                  color: "white",
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
              >
                {statusInfo}
              </p>
            </div>

            <p style={{ color: "white", fontWeight: "bold" }}>{statusText}</p>
          </div>
          <Card selectedOption={selectedOption} />
        </div>
      )}
      {!formSubmitted && !formFiatSubmitted && renderForm()}
      {/* Render the new card if form is submitted */}
      {!formSubmitted && formFiatSubmitted && (
        <div style={{ margin: "20px" }}>
          <HeaderPaymentMethodFiat />
          <div style={PaymentcontainerStyle}>
            <form style={formStyle} onSubmit={handleSubmitPaymentFiat}>
              <div>
                <div
                  style={{
                    backgroundColor: "#363636",
                    padding: "20px",
                    borderRadius: "20px",
                  }}
                >
                  <div style={FinalrowContainerStyle}>
                    <p style={FinalLabelStyle}>Kurs</p>
                    <div style={FinalResultStyle}>
                      {formatNumber(kurs || 0)}
                    </div>
                  </div>
                  <div style={FinalrowContainerStyle}>
                    <p style={FinalLabelStyle}>Jumlah IDR</p>
                    <div style={FinalResultStyle}>
                      {formatNumber(amount || 0)}
                    </div>
                  </div>

                  <div style={FinalrowContainerStyle}>
                    <p style={FinalLabelStyle}>Platform Tujuan</p>
                    <div style={FinalResultStyle}>
                      {destinationPlatform === "Mexc"
                        ? "Mexc"
                        : destinationPlatform === "pintu"
                        ? "Pintu"
                        : "User ID"}
                    </div>
                  </div>
                  <div style={FinalrowContainerStyle}>
                    <p style={FinalLabelStyle}>ID Akun Tujuan</p>
                    <div style={FinalResultStyle}>{userID}</div>
                  </div>
                  <div style={FinalrowContainerStyle}>
                    <p style={FinalLabelStyle}>Biaya</p>
                    <div style={FinalFeeResultStyle}>Gratis</div>
                  </div>
                  <svg height="5" width="100%">
                    <line
                      x1="0"
                      y1="0"
                      x2="100%"
                      y2="0"
                      style={{ stroke: "#25E9E2", strokeWidth: "4" }}
                    />
                  </svg>

                  <div style={FinalrowContainerStyle}>
                    <p style={FinalLabelStyle}>Kamu akan dapat</p>
                    {/* <div style={FinalResultStyle}>{amount / limitIDR} USDT</div> */}
                    <div style={FinalResultStyle}>
                      {(finalFiat || 0).toFixed(2)} USDT
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label htmlFor="PaymentdestinationBank" style={labelStyle3}>
                    Pilih Metode Transfer
                  </label>
                  <select
                    id="PaymentdestinationBank"
                    name="PaymentdestinationBank"
                    value={PaymentdestinationBank}
                    onChange={(e) => setPaymentdestinationBank(e.target.value)}
                    style={{
                      backgroundColor: "#363636",
                      padding: "20px",
                      borderRadius: "10px",
                      color: "#fff",
                      border: "none",
                      boxSizing: "border-box", // Include padding and border in the width
                    }}
                  >
                    <option disabled={true} value="">
                      Pilih Satu
                    </option>
                    <option value="BCA">BCA</option>
                    <option value="SEABank">SEA Bank</option>
                  </select>
                  <label htmlFor="PaymentaccountNumber" style={labelStyle3}>
                    Nomor Rekening Pengirim
                  </label>
                  <input
                    type="text"
                    id="PaymentaccountNumber"
                    name="PaymentaccountNumber"
                    value={PaymentaccountNumber}
                    onChange={(e) => setPaymentaccountNumber(e.target.value)}
                    placeholder="123XXX"
                    style={{
                      backgroundColor: "#363636",
                      padding: "20px",
                      borderRadius: "10px",
                      color: "#fff",
                      border: "none",
                      boxSizing: "border-box", // Include padding and border in the width
                    }}
                  />
                  <label htmlFor="PaymentaccountOwnerName" style={labelStyle3}>
                    Nama Rekening Pengirim
                  </label>
                  <input
                    type="text"
                    id="PaymentaccountOwnerName"
                    name="PaymentaccountOwnerName"
                    value={PaymentaccountOwnerName}
                    onChange={(e) => setPaymentaccountOwnerName(e.target.value)}
                    placeholder="Budi"
                    style={{
                      backgroundColor: "#363636",
                      padding: "20px",
                      borderRadius: "10px",
                      color: "#fff",
                      border: "none",
                      boxSizing: "border-box", // Include padding and border in the width
                    }}
                  />
                  <button
                    type="submit"
                    style={submitPaymentFiatButtonStyle}
                    disabled={!isFiatPaymentMethodFormFilled()}
                  >
                    Lanjutkan
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {formSubmitted && !formFiatSubmitted && (
        <div style={{ margin: "20px" }}>
          <HeaderPaymentMethodCrypto />
          <div style={PaymentcontainerStyle}>
            <form style={formStyle} onSubmit={handleSubmitPaymentCrypto}>
              <div>
                <div
                  style={{
                    backgroundColor: "#363636",
                    padding: "20px",
                    borderRadius: "20px",
                  }}
                >
                  <div style={FinalrowContainerStyle}>
                    <p style={FinalLabelStyle}>Kurs</p>
                    <div style={FinalResultStyle}>
                      {formatNumber(kurs || 0)}
                    </div>
                  </div>
                  <div style={FinalrowContainerStyle}>
                    <p style={FinalLabelStyle}>Jumlah USDT</p>
                    <div style={FinalResultStyle}>{amount || 0}</div>
                  </div>
                  <div style={FinalrowContainerStyle}>
                    <p style={FinalLabelStyle}>Bank Tujuan</p>
                    <div style={FinalResultStyle}>{destinationBank}</div>
                  </div>
                  <div style={FinalrowContainerStyle}>
                    <p style={FinalLabelStyle}>Nomor Rekening</p>
                    <div style={FinalResultStyle}>{accountNumber}</div>
                  </div>
                  <div style={FinalrowContainerStyle}>
                    <p style={FinalLabelStyle}>Nama Penerima Rekening</p>
                    <div style={FinalResultStyle}>{accountOwnerName}</div>
                  </div>

                  <div style={FinalrowContainerStyle}>
                    <p style={FinalLabelStyle}>Biaya</p>
                    <div style={FinalFeeResultStyle}>Gratis</div>
                  </div>
                  <svg height="5" width="100%">
                    <line
                      x1="0"
                      y1="0"
                      x2="100%"
                      y2="0"
                      style={{ stroke: "#25E9E2", strokeWidth: "4" }}
                    />
                  </svg>
                  <div style={FinalrowContainerStyle}>
                    <p style={FinalLabelStyle}>Kamu akan dapat</p>
                    <div style={FinalResultStyle}>
                      {formatNumber(finalCrypto || 0)}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label htmlFor="PaymentdestinationCrypto" style={labelStyle2}>
                    Pilih Platform Tujuan
                  </label>
                  <select
                    id="PaymentdestinationCrypto"
                    name="PaymentdestinationCrypto"
                    value={PaymentdestinationCrypto}
                    onChange={(e) =>
                      setPaymentdestinationCrypto(e.target.value)
                    }
                    style={{
                      backgroundColor: "#363636",
                      // height: "40px",
                      padding: "20px",
                      borderRadius: "10px",
                      color: "#fff",
                      border: "none",
                      boxSizing: "border-box", // Include padding and border in the width
                    }}
                  >
                    <option disabled={true} value="">
                      Pilih Satu
                    </option>
                    <option value="Mexc">Mexc</option>
                    <option value="pintu">Pintu</option>
                  </select>
                  <label htmlFor="PaymentuserID" style={labelStyle2}>
                    {PaymentdestinationCrypto === "Mexc"
                      ? "UID Mexc Kamu"
                      : PaymentdestinationCrypto === "pintu"
                      ? "@username Pintu Kamu"
                      : "UID / @username"}
                  </label>
                  <input
                    type="text"
                    id="PaymentuserID"
                    name="PaymentuserID"
                    value={PaymentuserID}
                    onChange={(e) => setPaymentuserID(e.target.value)}
                    placeholder="123XXX"
                    style={{
                      backgroundColor: "#363636",
                      padding: "20px",
                      borderRadius: "10px",
                      color: "#fff",
                      border: "none",
                      boxSizing: "border-box", // Include padding and border in the width
                    }}
                  />
                  <button
                    type="submit"
                    style={submitPaymentCryptoButtonStyle}
                    disabled={!isCryptoPaymentMethodFormFilled()}
                  >
                    Lanjutkan
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tallcard;
