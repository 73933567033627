// src/components/CompoundCard.js
import React from "react";
import TallCard from "./Tallcard";

const CompoundCard = () => {
  return (
    <div>
      {/* <Card /> */}
      <TallCard />
    </div>
  );
};

export default CompoundCard;
