// src/components/Card.js
import React from "react";
import "./css/Card.css";

const containerStyle = {
  maxWidth: "90%", // Set your preferred maximum width percentage
  width: "520px",
  // height: "100px",
  height: "100%",
  paddingTop: "20px",
  paddingBottom: "20px",
  backgroundColor: "#525252",
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px",
  borderBottomLeftRadius: "0px",
  borderBottomRightRadius: "0px",
  display: "flex",
  justifyContent: "space-around", // Spread items evenly
  alignItems: "center",
  margin: "auto", // Center horizontally
};

const cardStyle = {
  color: "#fff",
  display: "flex",
  alignItems: "center", // Center children vertically
  width: "120px",
};

const buttonStyle = {
  width: "40px",
  height: "40px",
  // cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center", // Center content vertically
};
const spanStyle = {
  // marginLeft: '2px',
  fontWeight: "bold", // Make the text bold
};

const Card = ({ selectedOption }) => {
  const isByFiat = selectedOption === "fiat";

  return (
    <div style={containerStyle}>
      <div style={cardStyle}>
        {isByFiat ? (
          <>
            <img src="/idr.png" alt="Icon_Idr" className="idr-icon" />
            <span style={spanStyle}>IDR</span>
          </>
        ) : (
          <>
            <img src="/usdt.png" alt="Icon_Usdt" className="usdt-icon" />
            <span style={spanStyle}>USDT</span>
          </>
        )}
      </div>
      <div style={buttonStyle}>
        <img src="/switches.svg" alt="switch" />
      </div>
      <div style={cardStyle}>
        {isByFiat ? (
          <>
            <img src="/usdt.png" alt="Icon_Usdt" className="usdt-icon" />
            <span style={spanStyle}>USDT</span>
          </>
        ) : (
          <>
            <img src="/idr.png" alt="Icon_Idr" className="idr-icon" />
            <span style={spanStyle}>IDR</span>
          </>
        )}
      </div>
    </div>
  );
};

export default Card;
